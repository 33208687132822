<template>
  <v-container fluid>
    <v-card tile flat>
      <skeleton-page-heading v-if="loading" />
      <v-card-text v-else>
        <breadcrumb-component />
        <v-divider class="tw-mb-5"></v-divider>
        <action-bar
          :show="true"
          :refresh="refresh"
          :loading="loading"
          :search="search"
          :search-data="searchData"
          :route-create="routeCreate"
        />
        <v-divider class="tw-mt-5"></v-divider>
      </v-card-text>
      <v-card-text>
        <skeleton-table v-if="loading" />
        <server-side-table
          v-else
          :headers="headers"
          :footers="footers"
          :total-items="totalItems"
          :items="items"
          :loading="loading"
          :update-item-per-page="updateItemPerPage"
          :get-data-from-api="getDataFromApi"
          :remove-item="removeItem"
          :from="from ? from : 0"
          :total="total"
          :page="page"
          :route-edit="routeEdit"
          :action-show="true"
          :action-delete="true"
          :action-edit="true"
        />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import mixin from "@/mixins/mixin";
import BreadcrumbComponent from "../components/BreadcrumbComponent.vue";
import ActionBar from "../components/ActionBar.vue";
import ServerSideTable from "../components/ServerSideTable.vue";
import SkeletonTable from "../components/SkeletonTable.vue";
import SkeletonPageHeading from "../components/SkeletonPageHeading.vue";

export default {
  components: {
    BreadcrumbComponent,
    ActionBar,
    ServerSideTable,
    SkeletonTable,
    SkeletonPageHeading,
  },
  mixins: [mixin],
  data: () => ({
    url: "booth-coordinates",
    routeEdit: "booth-coordinate-edit",
    routeCreate: "booth-coordinate-create",
    headers: [
      { text: "#", value: "order" },
      { text: "Booth Type", value: "booth_type.type" },
      { text: "Number Type", value: "number_type", align: "center" },
      { text: "Top", value: "top", align: "center" },
      { text: "Left", value: "left", align: "center" },
      { text: "Width", value: "width", align: "center" },
      { text: "Height", value: "height", align: "center" },
      { text: "Action", value: "actions", align: "center" },
    ],
  }),
};
</script>

<style></style>
